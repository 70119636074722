<template>
	<table class="table-wr subtitle-1 black--text mt-5">
		<!-- Project name -->
		<tr>
			<td>{{ $t("app.project_name") }}</td>
			<th v-for="(item, index) in list" :key="index" :class="item.color">
				<div align="center" :class="`title font-weight-bold white--text`">
					{{ item.name }}
				</div>
			</th>
		</tr>
		<!-- Status -->
		<tr>
			<td>
				{{ $t("app.status") }}
			</td>
			<td v-for="(item, index) in list" :key="index">
				<div>
					{{ item.is_default ? $t("app.default") : "-" }}
					<v-icon v-if="item.is_default" right color="success" size="20"
						>$vuetify.icons.values.check_circle</v-icon
					>
				</div>
			</td>
		</tr>
		<!-- Progress -->
		<tr>
			<td>
				{{ $t("app.progress") }}
			</td>
			<td v-for="(item, index) in list" :key="index">
				<div>
					4 / 6 Modules Completed
				</div>
				<v-progress-linear
					v-model="item.progress"
					:color="'success'"
					height="20"
					class="mt-2"
				>
					<template v-slot:default="{ value }">
						<strong>{{ Math.ceil(value) }}%</strong>
					</template>
				</v-progress-linear>
			</td>
		</tr>
		<!-- Plan end date -->
		<tr>
			<td>
				{{ $t("app.plan_end_date") }}
			</td>
			<td v-for="(item, index) in list" :key="index">
				{{ item.end_date }}
			</td>
		</tr>
		<tr>
			<td>
				{{ $t("app.current_task") }}
			</td>
			<td v-for="(item, index) in list" :key="index">
				<div class="font-weight-bold">
					{{ item.current_module }}
				</div>
				<div>
					{{ item.current_stage }}
				</div>
			</td>
		</tr>
		<!-- Group name -->
		<tr>
			<td>
				{{ $t("app.group_name") }}
				<div class="font-weight-thin">({{ $t("app.if_any_group") }})</div>
			</td>
			<td v-for="(item, index) in list" :key="index">
				<div>
					{{ item.group ? item.group.name : "-" }}
				</div>
			</td>
		</tr>
		<!-- Mentor name -->
		<tr>
			<td>
				{{ $t("app.mentor_name") }}
				<div class="font-weight-thin">({{ $t("app.if_any_mentor") }})</div>
			</td>
			<td v-for="(item, index) in list" :key="index">
				<div>
					{{ (item.mentor ? item.mentor.username : "-") | capitalize }}
				</div>
			</td>
		</tr>
		<!-- Actions -->
		<tr>
			<td></td>
			<td
				v-for="(item, index) in list"
				:key="index"
				align="center"
				@click="setDefaultProject(item.id)"
			>
				<AppButton
					:label="
						item.is_default ? 'app.continue' : 'app.set_default_and_continue'
					"
					:block="true"
					:color="item.is_default ? 'success' : 'info'"
					:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
					:class="['font-weight-bold']"
				></AppButton>
			</td>
		</tr>
	</table>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { projectsManageMixin } from "@/mixins/projectsManageMixin.js";

export default {
	name: "ProjectsTable",

	mixins: [projectsManageMixin],

	data() {
		return {
			list: null,
		};
	},

	computed: {
		...mapState({
			projects: (state) => state.user.projects,
		}),

		...mapGetters({
			userProfile: "user/getProfile",
		}),
	},

	mounted() {
		this.listProjects();
	},

	methods: {
		async listProjects() {
			try {
				this.$loader.start();

				// Fetch projects from the API
				await this.$store.dispatch("user/listProjects");

				// Prepare data to use inside the template
				let arr = [];
				let count = 0;
				let colors = ["warning", "purple", "teal", "pink", "brown", "error"];
				this.projects.forEach((item, index) => {
					if (count >= 6) {
						count = 0;
					}
					arr.push({
						id: item.id,
						name: item.group
							? item.group.project_name
							: this.$t("app.research_project"),
						end_date: "1st Jan 2023",
						mentor: item.parent_user,
						group: item.group,
						current_module: item.available_steps[0].plan_step.module.name,
						current_stage: item.available_steps[0].plan_step.name,
						progress: Math.floor(Math.random() * (60 - 20 + 1)) + 20,
						is_default: item.default_profile,
						color: colors[count],
					});
					count++;
				});

				this.list = arr;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async setDefaultProject(id) {
			try {
				this.$loader.start();

				/**
				 * If user wants to switch to another project
				 * then clear the state of previos project
				 * but exclude "auth", "user", "globals", "notification"
				 * states because they are same for all projects.
				 */
				if (id != this.userProfile.id) {
					await this.$store.dispatch("resetAllState", [
						"auth",
						"globals",
						"notification",
					]);
				}

				// update default profile
				await this.$store.dispatch("user/updateProfile", {
					user_id: id,
					default_profile: true,
				});

				// Get all projects again
				await this.$store.dispatch("user/listProjects");

				// Set up data for dashboard
				await this.setUpDataForDashboard();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped lang="scss">
.table-wr {
	border-collapse: collapse;
	width: 100%;
	height: 100%;
	table-layout: fixed;
	td,
	th,
	tr {
		border: 1px solid var(--v-greyLighten2-base);
		padding: 10px 15px 10px 15px;
	}
	tr td:nth-child(1) {
		font-weight: bold;
		background: var(--v-blueLighten5-base);
	}
}
</style>
